import React, { useState } from 'react'
import './CSS/loginsignsup.css'
const LoginSignup = () => {

  const [state,setState] = useState("Đăng kí");
  const [fromData,setFromData] = useState({
    username:"",
    password:"",
    email:""
  })
  const changeHandler = (e) =>{
    setFromData({...fromData,[e.target.name]:e.target.value})
  }


  

  const login = async () =>{

    let responseData;
    await fetch('https://server.thiepcuoibanghia.com/login',{
      method:'POST',
      headers:{
        Accept:'application/form-data',
        'Content-Type':'application/json',
      },
      body: JSON.stringify(fromData),
    }).then((response)=>response.json()).then((data)=>responseData=data)

    if(responseData.success){
      localStorage.setItem('auth-token',responseData.token);
      window.location.replace("/");
      alert("Đăng Nhập Thành Công",fromData)
    } 
    else{
      alert(responseData.errors)
    }

  }



   const signup = async () =>{
    console.log("Tạo tài khoản thành công",fromData)
    let responseData;
    await fetch('https://server.thiepcuoibanghia.com/signup',{
      method:'POST',
      headers:{
        Accept:'application/form-data',
        'Content-Type':'application/json',
      },
      body: JSON.stringify(fromData),
    }).then((response)=>response.json()).then((data)=>responseData=data)

    if(responseData.success){
      localStorage.setItem('auth-token',responseData.token);
      window.location.replace("/");
    } 
    else{
      alert(responseData.error)
    }
  }

  return (
    <div className='loginsignup'>
      <div className="loginsignup-container">
        <h1>{state}</h1>
        <div className="loginsignup-fields">
          {state==="Đăng kí"?<input name='username' value={fromData.username} onChange={changeHandler} type="text" placeholder='Họ và tên' />:<></>}
          <input name='email' value={fromData.email} onChange={changeHandler} type="email" placeholder='Số Điện Thoại' />
          <input name='password' value={fromData.password} onChange={changeHandler} type="password" placeholder='Mật Khẩu' />
        </div>
        <button onClick={()=>{state==="Đăng Nhập"?login():signup()}}>Continue</button>
        {state==="Đăng kí"
        ? <p className="loginsignup-login">Bạn đã có tài khoản? <span onClick={()=>{setState("Đăng Nhập")}}>Đăng Nhập</span></p>
      :<p className="loginsignup-login">Tạo tài khoản mới <span onClick={()=>{setState("Đăng kí")}}>Click here</span></p>}
        <div className="loginsignup-agree">
          <input type="checkbox" name='' id='' />
          <p>By continuing, i agree to the terms of use & privacy policy</p>
        </div>
      </div>
    </div>
  )
}

export default LoginSignup
