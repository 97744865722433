import React from 'react'
import './Hero.css'
import { Link } from 'react-router-dom';
// import hand_icon from '../Assets/hand_icon.png'
import arrow_icon from '../Assets/arrow.png'
// import hero_imae from '../Assets/hero_image.png'
const Hero = () => {
  return (
    <div className='hero'>
      <div className='hero-left'>
        <h2>Thiệp cưới sang trọng và hiện đại !</h2>
            <div className='hero-hand-icon'>
                <p>Thiết kế in ấn theo yêu cầu!</p>
                {/* <img src={hand_icon} alt=""/> */}
            </div>
            <p>Sự lựa chọn đáng tin cậy!</p>
            <p></p>
            <Link style={{textDecoration: 'none'}} to="https://zalo.me/0389387815">            
            <div className="hero-latest-btn">
               <div>Liên Hệ</div>
               <img src={arrow_icon} alt="" />
            </div>
            </Link>
      </div>
      <div className="hero-right">
        {/* <img src={hero_imae} alt="" /> */}
      </div>
    </div>
  )
}

export default Hero
