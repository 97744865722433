import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Shop from "./Pages/Shop";
import ShopCategory from "./Pages/ShopCategory";
import Product from "./Pages/Product";
import Cart from "./Pages/Cart";
import LoginSignup from "./Pages/LoginSignup";
import Footer from "./Components/Footer/Footer";
import banner1 from "./Components/Assets/banner1.png";
import banner2 from "./Components/Assets/banner2.png";
import banner3 from "./Components/Assets/banner3.png";
import banner4 from "./Components/Assets/banner4.png";
import { useEffect } from "react";
function App() {
  useEffect(()=>{
    console.log('render');
  },[])
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Shop />} />
          <Route
            path="/thiephds"
            element={<ShopCategory banner={banner1} category="thiephd" />}
          />
          <Route
            path="/thieptts"
            element={<ShopCategory banner={banner2} category="thieptt" />}
          />
          <Route
            path="/thiepgrs"
            element={<ShopCategory banner={banner3} category="thiepgr" />}
          />
          <Route
            path="/thiepsnt"
            element={<ShopCategory banner={banner4} category="thiepsn" />}
          />

          <Route path="/product" element={<Product />}>
            <Route path=":productId" element={<Product />} />
          </Route>
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<LoginSignup />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
