import React from 'react'
import './Footer.css'
import footer_logo from '../Assets/logo_prrinting.png'
import facebook_icon from '../Assets/facebook.png'
import zalo_icon from '../Assets/zalo.png'
import dienthoai_icon from '../Assets/dienthoai.png'
import map_icon from '../Assets/map.png'

import { Link } from 'react-router-dom';



const Footer = () => {
  return (
    <div className='footer'>
      <div className="footer-logo">
        <img src={footer_logo} alt="" />
        <p className='ba'>BÁ</p><p className='ng'>NGHĨA</p> <p></p>
      </div>
        <ul className='footer-links'>
            <li>Company</li>
            <li>Products</li>
            <li>Offices</li>
            <li>About</li>
            <li>Contact</li>
        </ul>
        <div className="footer-social-icon">

            <Link to="https://www.facebook.com/profile.php?id=100008397450288">            
            <div className="footer-icons-container">
              <img src={facebook_icon} alt="" />
            </div>
            </Link>
            <Link to="https://zalo.me/0389387815">            
            <div className="footer-icons-container">
              <img src={zalo_icon} alt="" />
            </div>
            </Link>
            <Link to="tel:0365400916">            
            <div className="footer-icons-container">
                <img src={dienthoai_icon} alt="" />
            </div>
            </Link>
            <Link to="https://maps.app.goo.gl/8n9jhUD7NSDaNYp68">            
            <div className="footer-icons-container">
                <img src={map_icon} alt="" />
            </div>
            </Link>

        </div>
        <div className="footer-copyright">
            <hr />
            <p>Coppyright @ 2024 - Nguyễn Quốc Thái</p>
        </div>
    </div>
  )
}

export default Footer
