import React, { useContext } from 'react'
import './ProductDisplay.css'
import star_icon from '../Assets/star_icon.png'
import start_dull_icon from '../Assets/star_dull_icon.png'
import { ShopContext } from '../../Context/ShopContext'
import facebook_icon from '../Assets/facebook.png'
import zalo_icon from '../Assets/zalo.png'
import dienthoai_icon from '../Assets/dienthoai.png'
import map_icon from '../Assets/map.png'

import { Link } from 'react-router-dom';

const ProductDisplay = (props) => {
    const {product} = props;
    const {addToCart} = useContext(ShopContext);
  return (
    <div className='productdisplay'>
      <div className="productdisplay-left">
        <div className="productdisplay-img-list">
            <img src={product.image} alt="" />
            <img src={product.image} alt="" />
            <img src={product.image} alt="" />
            <img src={product.image} alt="" />
        </div>
        <div className="productdisplay-img">
            <img className='productdisplay-main-img' src={product.image} alt="" />
        </div>
      </div>

      <div className="productdisplay-right">
            <h1>{product.name}</h1>
            <div className='productdisplay-right-stars'>
                <img src={star_icon} alt="" />
                <img src={star_icon} alt="" />
                <img src={star_icon} alt="" />
                <img src={star_icon} alt="" />
                <img src={start_dull_icon} alt="" />
                <p>(122)</p>
            </div>
            <div className="productdisplay-right-prices">
                <div className="productdisplay-right-price-new">{product.new_price}Đ</div>
                <div className="productdisplay-right-price-old">{product.old_price}Đ</div>
            </div>
            {/* <div className="productdisplay-right-description">
            Thiệp cưới là sự phối hợp hài hòa giữa màu sắc và thiết kế. Một tấm thiệp cưới màu vàng đồng, 
            ý nghĩa của tấm thiệp cưới màu này như ngầm “bóc trần” 
            cô dâu và chú rể là những người yêu thích sự sang trọng, quý phái.
            </div> */}
            <div className="productdisplay-right-size">
              {/* <h1>Select SiZe </h1>
              <div className="productdispaly-right-sizes">
                <div>S</div>
                <div>M</div>
                <div>L</div>
                <div>XL</div>
                <div>XXL</div>
              </div> */}
            </div>
            <button onClick={()=>{addToCart(product.id)}}>Thêm Vào Giỏ</button>
            <p className='productdisplay-right-category'><span>Category: </span>Thiepgr, thiephđ, newdesign</p>
            <p className='productdisplay-right-category'><span>Tags: </span>Maungang,Latest</p>
            <p className='productdisplay-right-category'><span>Lưu ý: </span>Giá trên web chỉ áp dụng với số lượng thiệp trên 300 thiệp</p>

            <div className="footer-social-icon">

<Link to="https://www.facebook.com/profile.php?id=100008397450288">            
<div className="footer-icons-container">
  <img src={facebook_icon} alt="" />
</div>
</Link>
<Link to="https://zalo.me/0389387815">            
<div className="footer-icons-container">
  <img src={zalo_icon} alt="" />
</div>
</Link>
<Link to="tel:0365400916">            
<div className="footer-icons-container">
    <img src={dienthoai_icon} alt="" />
</div>
</Link>
<Link to="https://maps.app.goo.gl/8n9jhUD7NSDaNYp68">            
<div className="footer-icons-container">
    <img src={map_icon} alt="" />
</div>
</Link>

</div>

      </div>
    </div>
  )
}

export default ProductDisplay 
