import React from 'react'
import './DescriptionBox.css'
const DescriptionBox = () => {
  return (
    <div className='descriptionbox'>
      <div className="descriptionbox-navigator">
        <div className="descriptionbox-nav-box">Description</div>
        <div className="descriptionbox-nav-box fade">Reviews (122)</div>
      </div>
      <div className="descriptionbox-description">
        <p>   Thiệp cưới là sự phối hợp hài hòa giữa màu sắc và thiết kế. Một tấm thiệp cưới màu vàng đồng, 
            ý nghĩa của tấm thiệp cưới màu này như ngầm “bóc trần” 
            cô dâu và chú rể là những người yêu thích sự sang trọng, quý phái.Thiệp cưới là sự phối hợp hài hòa giữa màu sắc và thiết kế. Một tấm thiệp cưới màu vàng đồng, 
            ý nghĩa của tấm thiệp cưới màu này như ngầm “bóc trần” 
            cô dâu và chú rể là những người yêu thích sự sang trọng, quý phái.</p>
      </div>
    </div>
  )
}

export default DescriptionBox
