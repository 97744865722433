import React, { useContext, useRef, useState } from 'react'
import './Navbar.css'
import logo from '../Assets/logo_prrinting.png'
import cart_icon from '../Assets/cart_icon.png'
import { Link } from 'react-router-dom'
import { ShopContext } from '../../Context/ShopContext'
import iconmenu_ref from '../Assets/dropdownIcon.png'
const Navbar = () => {

    const [menu,setMenu] = useState("shop");
    const {getTotalCartItems} = useContext(ShopContext)
    const menuRef = useRef();

    const dropdown_toggle = (e) =>{
        menuRef.current.classList.toggle('nav-menu-visible');
        e.target.classList.toggle('open');
    }

  return (
    <div className='navbar'>
        <div  className='nav-logo'>
        <Link onClick={()=>{setMenu("shop")}} style={{textDecoration: 'none'}} to='/'><img  src={logo} alt=""  /></Link>{menu==="shop"?<hr/>:<></>}
        <Link onClick={()=>{setMenu("shop")}} style={{textDecoration: 'none'}} to='/'><p className='ba'>BÁ</p><p className='ng'>NGHĨA</p></Link>{menu==="shop"?<hr/>:<></>}

        {/* <Link style={{textDecoration: 'none'}} to='/'><img  src={logo} alt=""  /></Link> */}
            {/* <img  src={logo} alt=""  /> */}
            {/* <Link style={{textDecoration: 'none'}} to='/'><p className='ba'>BÁ </p><p className='ng'>NGHĨA</p></Link> */}
            {/* <p className='ba'>BÁ </p><p className='ng'>NGHĨA</p> <p></p> */}
        </div>
        <img className='nav-dropdown' onClick={dropdown_toggle} src={iconmenu_ref} alt="" />
        <ul ref={menuRef} className="nav-menu">
            <li onClick={()=>{setMenu("shop")}}><Link style={{textDecoration: 'none'}} to='/'>TRANG CHỦ</Link>{menu==="shop"?<hr/>:<></>} </li>
            <li onClick={()=>{setMenu("thiephds")}}><Link style={{textDecoration: 'none'}} to='/thiephds'>THIỆP CƯỚI HIỆN ĐẠI</Link>{menu==="thiephds"?<hr/>:<></>}</li>
            <li onClick={()=>{setMenu("thieptts")}}><Link style={{textDecoration: 'none'}} to='/thieptts'>THIỆP CƯỚI TRUYỀN THỐNG</Link>{menu==="thieptts"?<hr/>:<></>}</li>
            <li onClick={()=>{setMenu("thiepgrs")}}><Link style={{textDecoration: 'none'}} to='/thiepgrs'>THIỆP CƯỚI GIÁ RẺ</Link>{menu==="thiepgrs"?<hr/>:<></>}</li>
            <li onClick={()=>{setMenu("thiepsnt")}}><Link style={{textDecoration: 'none'}} to='/thiepsnt'>THIỆP SINH NHẬT</Link>{menu==="thiepsnt"?<hr/>:<></>}</li>
        </ul>
        <div className="nav-login-cart">
            {localStorage.getItem('auth-token')
            ?<button onClick={()=>{localStorage.removeItem('auth-token');window.location.replace('/')}}>Logout</button>
            :<Link to='/login'><button>ĐĂNG NHẬP</button></Link>}
            <Link to='/cart'><img src={cart_icon} alt="" /></Link>
            <div className="nav-cart-count">{getTotalCartItems()}</div>
        </div>
    </div>
  )
}

export default Navbar
