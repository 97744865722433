import React, { useEffect, useState } from 'react'
import './RelatedProducts.css'
// import data_product from '../Assets/data'
import Item from '../Item/Item'
import { API_URL } from '../../config';
const RelatedProducts = () => {

  const [new_relatedproduct,setNew_relatedproduct] = useState([]);

  useEffect(()=>{
    fetch(API_URL+'/relatedproducts')
    .then((response)=>response.json())
    .then((data)=>setNew_relatedproduct(data))
 },[])

  return (
    <div className='relatedproducts'>
      <h1>SẢN PHẨM MỚI</h1>
      <hr />
      <div className="relatedproducts-item">
        {new_relatedproduct.map((item,i)=>{
            return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price}/> 
        })}
      </div>
    </div>
  )
}

export default RelatedProducts
