import React, { useContext } from 'react'
import './CartItems.css'
import { ShopContext } from '../../Context/ShopContext'
import remove_icon from '../Assets/cart_cross_icon.png'
import { Link } from 'react-router-dom';

const CartItems = () => {
  const {getTotalCartAmount,all_product,cartItems,removeFromCart} = useContext(ShopContext)
  return (
    <div className='cartitems'>
      <div className="cartitems-format-main">
        <p>Sản Phẩm</p>
        <p>Tên</p>
        <p>Giá</p>
        <p>Số lượng</p>
        <p>Tổng</p>
        <p>Xóa</p>
      </div>
      <hr />
      {all_product.map((e)=>{
        if(cartItems[e.id]>0)
        {
          return       <div>
          <div className="cartitems-format cartitems-format-main">
            <img src={e.image} alt="" className='carticon-product-icon' />
            <p>{e.name}</p>
            <p>${e.new_price}</p>
            <button className='cartitems-quantity'>{cartItems[e.id]}</button>
            <p>{e.new_price*cartItems[e.id]} VNĐ</p>
            <img className='cartitems-remove-icon' src={remove_icon} onClick={()=>{removeFromCart(e.id)}} alt="" />
          </div>
          <hr />
        </div>
        }
        return null;
      })}
      <div className="cartitems-down">
        <div className="cartitems-total">
          <h1>Tổng số tiền dự tính</h1>
          <div>
            <div className="cartitems-total-item">
              <p>Thành tiền</p>
              <p>{getTotalCartAmount()} VNĐ</p>
            </div>
            <hr />
            <div className="cartitems-total-item">
              <p>Ship Free</p>
              <p> Free</p>
            </div>  
            <hr />
            <div className="cartitems-total-item">
                  <h3>Tổng</h3>
                  <h3>{getTotalCartAmount()} VNĐ</h3>
            </div>
          </div>
          <Link to="https://zalo.me/0389387815">   
          <button>Liên hệ ngay</button>         
            </Link>
        </div>
        <div className="cartitems-promocode">
          <p>Nhập mã giảm giá</p>
          <div className="cartitems-promobox">
            <input type="text" placeholder='code' />
            <button>Nhập</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartItems
